import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";

import "react-accessible-accordion/dist/fancy-example.css";
import { redirectToWithUtm } from "../../../components/Header";
const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const BlogHeader = require("../../../assets/img/blogs/header_blog_5Benefits.png.webp");
const Section1 = require("../../../assets/img/blogs/blog_whats_new.png.webp");
const Section2 = require("../../../assets/img/blogs/chatbot_best_2.png");
const Section3 = require("../../../assets/img/blogs/chatbot_best_3.jpeg");
const Section4 = require("../../../assets/img/blogs/Blog_Chatbot Best Practices_1.jpg");
const Section5 = require("../../../assets/img/blogs/chatbot_best_5.png");
const Section6 = require("../../../assets/img/blogs/chatbot_best_6.png");
const Section7 = require("../../../assets/img/blogs/chatbot_best_7.png");
const Section8 = require("../../../assets/img/blogs/chatbot_best_8.png");
const SectionLast = require("../../../assets/img/blogs/chatbot_best_final.gif");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Automate Password Requests with Chatbots: Enhance User Experience"
        description="Learn how employees can unlock account using chatbot task automation in seconds using workativ assistant&#039;s conversational ai and task automation platform. "
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={BlogHeader}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Reset Password Requests: 5-star rating with Workativ
                            Assistant
                          </h1>
                        </div>
                      </div>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <h2 className="font-section-sub-header-small">
                          Your account is locked. Please use the link to reset
                          your password.
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          It happens one day or the other. Every IT professional
                          has experienced it sometimes. An employee tries to
                          access her corporate e-mail account or business
                          portal. She must present a report in a meeting
                          starting in a few minutes. But her account is locked.
                          She enters and reenters the password several times,
                          tries all the numbers, combinations, and special
                          characters, but still, the account is inaccessible.
                        </p>
                        <img loading="lazy" src={Section1} />
                        <p class="font-section-normal-text mt-3 line-height-2">
                          Account lockouts are the common headache of IT people
                          as every technology they use involve login. They must
                          operate their business PCs, smartphones, and tablets
                          and keep in mind no less than 10 different passwords,
                          each of them more complex than the other.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Although security locks might occur for security
                          reasons, a locked account represents unproductive
                          work-stoppage. Reset may seem a simple step at first,
                          but it negatively impacts the{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation">
                            IT agent’s workload
                          </a>{" "}
                          and demands a huge amount of their time and effort on
                          lockouts and resets.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h6 class="font-section-sub-header-small">
                          Automation: one big solution
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/use-cases/reset-password-automation">
                            Automation of this monotonous and repetitive
                            password reset process{" "}
                          </a>
                          is a better strategic approach than sorting out the
                          issue with the manual workforce. After all, nearly
                          30-40% of helpdesk support cases involve repetitious
                          tasks, reset password and{" "}
                          <a href="https://workativ.com/use-cases/unlock-account-automation">
                            unlocking process{" "}
                          </a>
                          being the major part of it.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With IT helpdesk chatbots, this tiresome practice can
                          be entirely automated, and it will take just a few
                          minutes to resolve the issues.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          This is how reset password works on{" "}
                          <a href="https://workativ.com/">
                            Workativ Assistant.{" "}
                          </a>
                          In simple words, it does it for you!
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          With Workativ Assistant, you create automated helpdesk
                          Bot that has in-built{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-focus-on-user-experience">
                            Conversational AI and NLU{" "}
                          </a>
                          to understand, analyze, and respond with remarkable
                          accuracy. The employee has to simply type in{" "}
                          <a href="https://workativ.com/use-cases/reset-password-automation">
                            Reset Password
                          </a>{" "}
                          into their Slack or Teams
                        </p>
                        <ExistingBlogCta
                          ContentCta="Explore the Power of Workativ Assistant."
                          ButtonText="Book a Demo"
                          isColor="white"
                          backgroundImage={cta_2}
                          mobileBackgroundImage={cta_2_mob}
                        />
                        <p class="font-section-normal-text line-height-2">
                          window. The Bot understands and allows the user to key
                          in a new password (of course, with some security
                          inputs) but there’s absolutely no clicking of a link
                          to do it, no chatting in person, no searching a
                          knowledge base and no waiting! The new-gen digital
                          assistant works like magic for employee requests like
                          unlock account and reset password.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          “My account is locked” requests can have a better
                          alternative and how does it improve the productivity
                          of IT helpdesk agents?
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform">
                            Chatbots
                          </a>{" "}
                          have revolutionized the face of customer service in
                          just a few years with innovative human-like
                          intelligence. It helps companies to optimize corporate
                          help desk with its advanced intelligence.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          It is important to know that resetting passwords are
                          not the only tasks of an agent. If an agent gets
                          caught in attending tickets and phone calls for these
                          recurrent activities, he will not be able to solve
                          more complex and attention-seeking tasks.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Automation makes life easier in the long term.
                          <a href="https://blog.workativ.com/what-is-workativ-assistant/">
                            Intelligent virtual assistants
                          </a>{" "}
                          can analyze a request via text inputs and complete the
                          request in a matter of seconds or minutes. This
                          simplified and seamless process helps the IT staffs to
                          be more focused and productive.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          The plus with a digital assistant – Unlike human
                          agents, who are unavailable during non-business hours,
                          bots will be there to{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-support-24-7">
                            assist 24 hours a day, 7 days a week.
                          </a>{" "}
                          That’s what millions of IT employees are looking for!
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot ">
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
